import React, { useEffect, useMemo } from 'react'
import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import LoadingSkeleton from '../../../../../components/LoadingSkeleton'
import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query'
import { InfiniteScrollTable } from '../../../../../components/Table'
import { useAuthContext } from '../../../../../context/AuthContext/useAuthContext'
import { getBenchmarkClinicSummaryTableScroll } from '../../../../../services/apis'
import { useFilterContext } from '../../../../../context/FilterContext/useFilterContext'

const FetchData = async ({ params, token }) => {
  return await getBenchmarkClinicSummaryTableScroll({ accessToken: token, data: params })
}

/**
 * Renders a table component for displaying clinic records.
 *
 
 * @param {Function} handleRowClick - The function to handle row click events.
* @param {string} filteredNameClinic - Filter name of clinic to want search.
* @param {Function} isFilterApplyOfClinicName - The function to manage the name of to want search and send to server
* @param {boolean} setIsFilterApplyOfClinicName - A flag indicating the name of clinic is send to server and filter, to not send many times to server.
* @param {string} setSelectedClinicId - We set for the first time the clinic id, because the data is filtered by the infiniteScroll component and we need to know the first time it brought data and for the first time get the clinic id as selected by default .
* @param {boolean} isLoading - A flag indicating whether the table is currently loading data.
* @returns {JSX.Element} The rendered table component.
 */
export function ClinicListTable({
  handleRowClick,
  filteredNameClinic = '',
  isFilterApplyOfClinicName,
  setIsFilterApplyOfClinicName,
  setSelectedClinicId,
  setSelectedClinicDetails,
  isLoadingTable,
}) {
  const TABLE_HEIGHT = 250

  const { accessToken } = useAuthContext()
  const { selectedScenario, unitOfMeasurement, unitOfTime } = useFilterContext()
  const [sorting, setSorting] = React.useState([{ id: 'id', desc: false }])
  const [columnVisibility, setColumnVisibility] = React.useState({
    clinic_id: false,
  })
  const [keyNameInfiniteScroll, SetKeyNameInfiniteScroll] = React.useState('clinic_names')
  const [keyOfClinicName, setKeyOfClinicName] = React.useState(filteredNameClinic)
  const [rowSelection, setRowSelection] = React.useState({})

  const columns = React.useMemo(
    () => [
      {
        id: 'clinic_id',
        accessorFn: row => row.clinic_id,
        cell: info => info.getValue(),
        enableSorting: true,
        flex: 1,
        header: () => <span>Clinic id</span>,
        sortDescFirst: false,
      },
      {
        id: 'clinic_name',
        accessorFn: row => row.clinic_name,
        cell: info => info.getValue(),
        enableSorting: true,
        flex: 2,
        header: () => <span>Clinic Name</span>,
        sortDescFirst: false,
      },
      {
        id: 'clinic_full_address',
        accessorFn: row => row.clinic_full_address,
        cell: info => info.getValue(),
        enableSorting: true,
        flex: 2,
        header: () => <span>Clinic Address</span>,
        sortDescFirst: false,
      },
      {
        id: 'avg_scheduled_appointments',
        accessorFn: row => row.avg_scheduled_appointments,
        cell: info => info.getValue(),
        enableSorting: true,
        flex: 1,
        header: () => <span>Average Scheduled Appointments</span>,
        sortDescFirst: false,
      },
      {
        id: 'completed_appointments',
        accessorFn: row => row.completed_appointments,
        cell: info => info.getValue(),
        enableSorting: true,
        flex: 1,
        header: () => <span>Completed Appointments</span>,
        sortDescFirst: false,
      },
      {
        id: 'billable_visits',
        accessorFn: row => row.billable_visits,
        cell: info => info.getValue(),
        enableSorting: true,
        flex: 1,
        header: () => <span>Billable Visits</span>,
        sortDescFirst: false,
      },
      {
        id: 'ie_visits',
        accessorFn: row => row.ie_visits,
        cell: info => info.getValue(),
        enableSorting: true,
        flex: 1,
        header: () => <span>IE Visits</span>,
        sortDescFirst: false,
      },
      {
        id: 'units_per_visit',
        accessorFn: row => row.units_per_visit,
        cell: info => info.getValue(),
        enableSorting: true,
        flex: 1,
        header: () => <span>Units per Visit</span>,
        sortDescFirst: false,
      },
      {
        id: 'ie_visit_ratio',
        accessorFn: row => (row.ie_visit_ratio === null ? '' : (row.ie_visit_ratio * 100).toFixed(1) + '%'),
        cell: info => info.getValue(),
        enableSorting: true,
        flex: 1,
        header: () => <span>IE to Visit Ratio</span>,
        sortDescFirst: false,
      },
    ],
    [],
  )

  const itemsPerPage = Number(window?.env?.ITEMS_PER_PAGE || 10)
  const startIndex = 0

  const params = {
    parameters: {
      units_payload: {
        rate: unitOfMeasurement,
        time_granularity: unitOfTime,
      },
      scenario_id: selectedScenario?.scenarioid,
      clinic_details: {
        clinic_name: filteredNameClinic,
      },
    },
    pagination_details: {
      items_per_page: itemsPerPage,
      start_index: startIndex,
    },
  }

  //This part of code is to manage the key of infinite scroll with the combination of [unitOfMeasurement, unitOfTime, selectedScenario, keyOfClinicName]
  const queryKey = useMemo(
    () => [keyNameInfiniteScroll, unitOfMeasurement, unitOfTime, selectedScenario, keyOfClinicName],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [unitOfMeasurement, unitOfTime, selectedScenario, keyOfClinicName],
  )

  //This part is when filter was applied of clinic name to send the search criteria to the server to filter the records, to remove cache of infinite scroll component
  //is changing name of hook clinicName
  useEffect(() => {
    const fetchData = async () => {
      if (isFilterApplyOfClinicName) {
        setKeyOfClinicName((Math.random() * 1000).toString())
      }
    }
    fetchData()
  }, [isFilterApplyOfClinicName])

  //This part of code is to manage the cache of the infinite scroll component when the filter is applied of [unitOfMeasurement, unitOfTime, selectedScenario, clinicName]
  useEffect(() => {
    SetKeyNameInfiniteScroll((Math.random() * 1000).toString())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryKey])

  const { data, fetchNextPage, isFetching, isLoading, refetch } = useInfiniteQuery({
    queryKey: queryKey,
    queryFn: async ({ pageParam = 0 }) => {
      params.pagination_details.start_index = pageParam

      if (!selectedScenario?.scenarioid) {
        return {
          data: [],
          meta: {
            totalRowCount: 0,
          },
        }
      }

      const rowsData = await FetchData({ params: params, token: accessToken })
      const summaryDataClinic = rowsData?.data?.summary_data_clinic || []
      const totalRowCount = parseInt(rowsData?.data?.pagination_details.total_row_count) || 0
      //To set the selected clinic id to the first row of the table at the first time
      if (pageParam === 0 && summaryDataClinic.length > 0 && Object.keys(rowSelection).length === 0) {
        const id = summaryDataClinic[0]?.clinic_id
        setSelectedClinicId(id)
        setSelectedClinicDetails(summaryDataClinic[0])
        setRowSelection({ [id]: true })
      }
      // Indicates whether a filter was applied to send the search criteria to the server to filter the records.
      setIsFilterApplyOfClinicName(false)
      return {
        data: summaryDataClinic,
        meta: {
          totalRowCount,
        },
      }
    },
    initialPageParam: 0,
    getNextPageParam: (_lastGroup, groups) => groups.length,
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
  })

  return (
    <>
      {isLoading || isLoadingTable ? (
        <LoadingSkeleton variant="rectangular" height={166} data-testid="loading-clinic-list-table" />
      ) : (
        <InfiniteScrollTable
          data={data}
          columns={columns}
          fetchNextPage={fetchNextPage}
          isFetching={isFetching}
          isLoading={isLoading} // This is really "isInitialLoad" and isFetching is true when loading more records while this is false
          sorting={sorting}
          setSorting={setSorting}
          columnVisibility={columnVisibility}
          setColumnVisibility={setColumnVisibility}
          enableSelectableRows={true}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          tableHeight={TABLE_HEIGHT}
          fetchMoreThreshold={35} // this value will vary depending on the height of the table, table rows, and the number of items fetched
          renderFetchingMsg={table => {
            if (isLoading) return null // do not display fetching message on initial load when no data (loading overlay will handle this)
            return (
              <Box
                sx={{
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                Loading clinics...
              </Box>
            )
          }}
          noRecordsOverlay={<div>No clinics found..</div>}
          handleRowClick={handleRowClick}
        />
      )}{' '}
    </>
  )
}
ClinicListTable.propTypes = {
  handleRowClick: PropTypes.func.isRequired,
  filteredNameClinic: PropTypes.string.isRequired,
  isFilterApplyOfClinicName: PropTypes.bool.isRequired,
  setIsFilterApplyOfClinicName: PropTypes.func.isRequired,
  setSelectedClinicId: PropTypes.func.isRequired,
  setSelectedClinicDetails: PropTypes.func.isRequired,
  isLoadingTable: PropTypes.bool.isRequired,
}

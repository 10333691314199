/*

    API Functions
    
    All functions to support all api calls.
    URL Root is stored in config file.


*/

import { ApiError } from '../utils/ApiError'
import { getConfig } from './config'
import { customFetch } from './customFetch'

const v1 = getConfig().apiVersion.v1
const { marketBenchmarkingApiPythonUrl: api_url_root, marketBenchmarkingApiUrl } = getConfig().apiUrl

/**
 * validateParsedJsonForErrs throws an error if an error message is detected in the body of the response
 * in order to more accurately detect errors with the python-api (which can return 200 responses even when errors occur)
 *
 * @param {Object} options - The options object
 * @param {string} options.error - The error message
 * @param {string} options.message - The message
 * @returns {void}
 */
const validateParsedJsonForErrs = ({ error, message } = { error: undefined, message: undefined }) => {
  if (typeof error === 'string') {
    if (message) {
      throw new Error(`${error} (${message})`)
    }
    throw new Error(error)
  }
}

/**
 * getResponseParsed is a helper function that returns the response object
 * in order to more accurately detect errors with the python-api (which can return 200 responses even when errors occur)
 *
 * @param {Object} response - The response object
 * @param {Object} options - The options object
 * @param {Array<number>} options.overrideStatusCodes - The status codes to return a response to handle in individual fetch function
 * @returns {Object}
 */
const getResponseParsed = async (response, { overrideStatusCodes } = {}, errorTargetName = '') => {
  const resp = await response.json()
  const reason = resp?.reason || 'An error occurred - reason unknown.'
  const errorMessage = resp?.message || ''
  const error = resp?.error || ''
  const reasonErrorMessage = `Reason:${reason}, ErrorMessage: ${errorMessage}, Error: ${error}`

  if (overrideStatusCodes?.includes(response.status)) {
    return resp
  }

  switch (response.status) {
    case 200:
    case 201:
      return resp
    case 400:
    case 409:
      throw new ApiError(reasonErrorMessage, { statusCode: response.status, errorTarget: errorTargetName })
    default:
      throw new ApiError(reasonErrorMessage, { statusCode: response.status })
  }
}

export async function validateUserPermissions(accessToken) {
  const url = `${marketBenchmarkingApiUrl}${v1}/users/is-authorized`

  const response = await customFetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    },
  })

  if (!response.ok) {
    return { status: response.status, message: response.statusText }
  }
  const data = await response.json()
  return { status: response.status, message: 'Ok', result: data }
}

export async function getUserPreference(token) {
  const url = `${marketBenchmarkingApiUrl}${v1}/users/preferences`

  const response = await customFetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  })

  if (!response.ok) {
    return { status: response.status, message: response.statusText }
  }

  const parsedResponse = await response.json()
  validateParsedJsonForErrs({ error: parsedResponse?.error, message: parsedResponse?.message })

  return { status: response.status, message: 'Ok', result: parsedResponse }
}

export async function createUser(token, user) {
  const url = `${marketBenchmarkingApiUrl}${v1}/users`

  const response = await customFetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify(user),
  })

  const parsedResponse = await response.json()
  validateParsedJsonForErrs({ error: parsedResponse?.error, message: parsedResponse?.message })

  return parsedResponse
}

/**
 * @TODO DELETE THIS AND ANY RELATED DEAD CODE
 */
export async function loginUser(data) {
  const url = api_url_root + 'login'
  const response = await customFetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  })
  return response.json()
}

export async function getMenuOptions(accessToken) {
  const url = `${marketBenchmarkingApiUrl}${v1}/menu-options`

  const response = await customFetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + accessToken },
  })
  const parsedResponse = await response.json()
  validateParsedJsonForErrs({ error: parsedResponse?.error, message: parsedResponse?.message })

  return parsedResponse
}

export async function listAllPortfolios(accessToken) {
  const url = `${marketBenchmarkingApiUrl}${v1}/portfolios`

  const response = await customFetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + accessToken },
  })
  const parsedResponse = await response.json()
  validateParsedJsonForErrs({ error: parsedResponse?.error, message: parsedResponse?.message })

  return parsedResponse
}

export async function listAllClinics(accessToken) {
  const url = `${marketBenchmarkingApiUrl}${v1}/clinics`

  const response = await customFetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + accessToken },
  })
  const parsedResponse = await response.json()
  validateParsedJsonForErrs({ error: parsedResponse?.error, message: parsedResponse?.message })

  return parsedResponse
}

/**
 * @TODO - Should remove after the feature for the new data manager page is complete
 * @deprecated
 */
export async function savePortfolio(data, accessToken) {
  const url = `${marketBenchmarkingApiUrl}${v1}/portfolios`

  const response = await customFetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + accessToken },
    body: JSON.stringify(data),
  })
  const savePortfolio = await response.json()
  validateParsedJsonForErrs({ error: savePortfolio?.error, message: savePortfolio?.message })

  return { status: response.status, success: true, data: savePortfolio }
}

export async function createPortfolio({ accessToken, data }) {
  const url = `${marketBenchmarkingApiUrl}${v1}/portfolios`

  const response = await customFetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + accessToken },
    body: JSON.stringify(data),
  })

  const createdPortfolio = await response.json(response)

  const reason = createdPortfolio?.reason || 'An error occured - reason unknown.'

  if (response.status === 409) {
    // error should be that there is a portfolio with the same name
    throw new ApiError(reason, { statusCode: response.status, errorTarget: 'portfolio_title' })
  }

  if (response.status === 400) {
    if (reason.includes('portfolio_title')) {
      // error should be that less than 3 characters, empty string, or string of whitespace
      throw new ApiError(reason, { statusCode: response.status, errorTarget: 'portfolio_title' })
    }

    // error should be that clinics_list is empty
    throw new ApiError(reason, { statusCode: response.status, errorTarget: 'list_of_clinics' })
  }

  if (response.status >= 400) {
    // catch all for unexpected errors & internal server errors
    throw new ApiError(reason, { statusCode: response.status })
  }

  return createdPortfolio
}

export async function updatePortfolio({ accessToken, data, id }) {
  const url = `${marketBenchmarkingApiUrl}${v1}/portfolios/${id}`

  const response = await customFetch(url, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + accessToken },
    body: JSON.stringify(data),
  })

  // If update is successful, return nothing
  if (response.status === 204) {
    return
  }

  const updatedPortfolio = await response.json(response)

  const reason = updatedPortfolio?.reason || 'An error occured - reason unknown.'

  if (response.status === 409) {
    // error should be that there is a portfolio with the same name
    throw new ApiError(reason, { statusCode: response.status, errorTarget: 'portfolio_title' })
  }

  if (response.status === 400) {
    // When not sending the portfolio_title
    throw new ApiError(reason, { statusCode: response.status, errorTarget: 'portfolio_title' })
  }

  if (response.status >= 400) {
    // catch all for unexpected errors & internal server errors
    throw new ApiError(reason, { statusCode: response.status })
  }

  return updatedPortfolio
}

export async function getEfficiencyMetricOverview(accessToken, data) {
  const url = api_url_root + 'getEfficiencyMetricOverview'

  const response = await customFetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + accessToken },
    body: JSON.stringify(data),
  })
  const efficientMetricsOverview = await response.json()
  validateParsedJsonForErrs({ error: efficientMetricsOverview?.error, message: efficientMetricsOverview?.message })

  return efficientMetricsOverview
}

export async function listScenarios(token) {
  const url = `${marketBenchmarkingApiUrl}${v1}/scenarios`

  const response = await customFetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept-Encoding': 'gzip, deflate, br',
      Authorization: 'Bearer ' + token,
      Connection: 'keep-alive',
    },
  })
  const listScenarios = await response.json()
  validateParsedJsonForErrs({ error: listScenarios?.error, message: listScenarios?.message })

  return {
    data: {
      scenario_list: listScenarios,
    },
  }
}

export async function getBenchmarkTrend(data, accessToken) {
  const url = `${api_url_root}getBenchmarkTrend`

  const response = await customFetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + accessToken },
    body: JSON.stringify(data),
  })
  const parsedResponse = await response.json()
  validateParsedJsonForErrs({ error: parsedResponse?.error, message: parsedResponse?.message })

  return parsedResponse
}

export async function getBenchmarkDistribution(accessToken, data) {
  const url = `${api_url_root}getBenchmarkDistribution`

  const response = await customFetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + accessToken },
    body: JSON.stringify(data),
  })
  const parsedResponse = await response.json()

  validateParsedJsonForErrs({ error: parsedResponse?.error, message: parsedResponse?.message })

  return parsedResponse
}

export async function getBenchmarkByState(data, accessToken) {
  const url = api_url_root + 'getBenchmarkByState'

  const response = await customFetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + accessToken },
    body: JSON.stringify(data),
  })
  const parsedRespones = await response.json()
  validateParsedJsonForErrs({ error: parsedRespones?.error, message: parsedRespones?.message })

  return parsedRespones
}

export async function getBenchmarkByDimension(data, accessToken) {
  const url = api_url_root + 'getBenchmarkByDimension'

  const response = await customFetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + accessToken },
    body: JSON.stringify(data),
  })
  const parsedResponse = await response.json()
  validateParsedJsonForErrs({ error: parsedResponse?.error, message: parsedResponse?.message })

  return parsedResponse
}

export async function getBenchmarkClinicSummaryTable({ accessToken, data }) {
  const url = api_url_root + 'getBenchmarkClinicSummaryTable'

  const response = await customFetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + accessToken },
    body: JSON.stringify(data),
  })
  const parsedResponse = await response.json()
  validateParsedJsonForErrs({ error: parsedResponse?.error, message: parsedResponse?.message })

  return parsedResponse
}

export async function getBenchmarkClinicSummaryTableScroll({ accessToken, data }) {
  const url = `${marketBenchmarkingApiUrl}${v1}/benchmarks/clinics-summary-table?pageIndex=${data.pagination_details.start_index}&itemsPerPage=${data.pagination_details.items_per_page}`

  const response = await customFetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + accessToken },
    body: JSON.stringify(data.parameters),
  })
  const parsedResponse = await getResponseParsed(response)

  return parsedResponse
}

export async function getClinicBenchmarkTrend(accessToken, data) {
  const url = api_url_root + 'getClinicBenchmarkTrend'

  const response = await customFetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + accessToken },
    body: JSON.stringify(data),
  })
  const parsedResponse = await response.json()
  validateParsedJsonForErrs({ error: parsedResponse?.error, message: parsedResponse?.message })

  return parsedResponse
}

export async function getScenarioParams(accessToken, data) {
  const url = api_url_root + 'getScenarioParams'

  const response = await customFetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + accessToken },
    body: JSON.stringify(data),
  })
  const parsedResponse = await response.json()
  validateParsedJsonForErrs({ error: parsedResponse?.error, message: parsedResponse?.message })

  return parsedResponse
}

/**
 * @TODO - Should remove after the feature for the new data manager page is complete
 * @deprecated
 */
export async function updateScenarioParameters(accessToken, data) {
  const url = api_url_root + 'updateScenarioParameters'

  const response = await customFetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + accessToken },
    body: JSON.stringify(data),
  })

  const parsedResponse = await response.json()

  validateParsedJsonForErrs({ error: parsedResponse?.error, message: parsedResponse?.message })
  return parsedResponse
}

/**
 * @param {Object} options - The options object
 * @param {string} options.accessToken - The access token
 * @param {Object} options.data - The data object
 * @param {Object} options.data.clinic_size - The clinic size range
 * @param {number} options.data.clinic_size.min - The minimum clinic size
 * @param {number} options.data.clinic_size.max - The maximum clinic size
 * @param {string} options.data.portfolioid - The portfolio ID
 * @param {string} options.data.scenario_title - The scenario name
 * @param {Array} options.data.therapist_type - The therapist type (array of designated strings )
 * @param {string} options.data.therapy_type - The therapy type
 * @param {Array} options.data.visit_type - The visit type accepts an array containing a single string
 * @param {Array} options.data.years - The years (an array of years written as strings)
 *
 * @returns {Promise<{
 *    scenario_parameters: {
 *      clinic_size: {min: number, max: number},
 *      portfolioid: string,
 *      scenario_title: string,
 *      therapist_type: string[],
 *      therapy_type: string,
 *      visit_type: string[],
 *      years: string[]
 *    }
 *    scenarioid: string,
 *   }
 * }
 */
export async function updateScenario({ accessToken, data }) {
  const url = api_url_root + 'updateScenarioParameters'

  const response = await customFetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + accessToken },
    body: JSON.stringify(data),
  })

  const updatedScenario = await response.json()

  const reason = updatedScenario?.reason || 'An error occured - reason unknown.'

  if (response.status === 409) {
    // error should be that there is a scenario with the same name
    throw new ApiError(reason, { statusCode: response.status, errorTarget: 'scenario_title' })
  }

  if (response.status === 400) {
    if (reason.toLowerCase().includes('name')) {
      // error should be that less than 3 characters, empty string, or string of whitespace
      throw new ApiError(reason, { statusCode: response.status, errorTarget: 'scenario_title' })
    }

    if (reason.toLowerCase().includes('year')) {
      // error should be that at least 1 year is selected
      throw new ApiError(reason, { statusCode: response.status, errorTarget: 'years' })
    }
  }

  if (response.status >= 400) {
    // catch all for unexpected errors & internal server errors
    throw new ApiError(reason, { statusCode: response.status })
  }

  return updatedScenario.data
}

/**
 * @param {Object} options - The options object
 * @param {string} options.accessToken - The access token
 * @param {Object} options.data - The data object
 * @returns {Promise<object>}
 */
export async function createScenario({ accessToken, data }) {
  const url = `${marketBenchmarkingApiUrl}${v1}/scenarios`

  const response = await customFetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + accessToken },
    body: JSON.stringify(data),
  })
  const createdScenario = await getResponseParsed(response, {}, 'scenario_title')

  return createdScenario
}

/**
 * @param {Object} data - The data object (includes access token)
 * @returns {Promise<object>}
 */
export async function deleteScenario(data) {
  const url = `${marketBenchmarkingApiUrl}${v1}/scenarios/${data.scenarioid}`

  const response = await customFetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Accept-Encoding': 'gzip, deflate, br',
      Authorization: 'Bearer ' + data.accessToken,
      Connection: 'keep-alive',
    },
  })

  const deletedScenario = await response.json()
  validateParsedJsonForErrs({ error: deletedScenario?.error, message: deletedScenario?.message })

  return deletedScenario
}

/**
 * @param {Object} data - The data object (includes access token)
 * @returns {Promise<object>}
 */
export async function defaultScenario({ accessToken, scenarioid }) {
  const url = `${marketBenchmarkingApiUrl}${v1}/scenarios/${scenarioid}/default`

  const response = await customFetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Accept-Encoding': 'gzip, deflate, br',
      Authorization: 'Bearer ' + accessToken,
      Connection: 'keep-alive',
    },
  })
  const defaultScenario = await getResponseParsed(response)

  return defaultScenario
}

export async function runBenchmark(accessToken, data) {
  const url = api_url_root + 'runBenchmark'

  const response = await customFetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + accessToken },
    body: JSON.stringify(data),
  })
  const parsedResponse = await response.json()
  validateParsedJsonForErrs({ error: parsedResponse?.error, message: parsedResponse?.message })

  return parsedResponse
}

export async function deletePortfolio({ accessToken, portfolioid }) {
  if (!portfolioid || !accessToken) {
    throw new Error('Portfolio ID and access token are required')
  }

  const url = `${marketBenchmarkingApiUrl}${v1}/portfolios/${portfolioid}`

  const response = await customFetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    },
  })

  const parsedResponse = await getResponseParsed(response, { overrideStatusCodes: [404, 422] })

  if (response?.status === 422) {
    const baseMessage = `The portfolio cannot be deleted until the following scenarios are assigned a different portfolio`
    const scenarioTitles = parsedResponse?.map(scenario => `"${scenario.scenario_title}"`).join(', ')
    throw new Error(`${baseMessage}: ${scenarioTitles}`)
  }

  if (response?.status === 404) {
    throw new Error(parsedResponse.message)
  }

  return parsedResponse
}

/**
 * Retrieves the map states from the API.
 * @param {Object} options - The options for the API request.
 * @param {string} options.accessToken - The access token for authentication.
 * @returns {Promise<Object>} - A promise that resolves to the parsed response from the API.
 */
export async function getMapStates({ accessToken }) {
  const url = `${marketBenchmarkingApiUrl}${v1}/map-states`

  const response = await customFetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    },
  })
  const parsedResponse = await response.json()
  validateParsedJsonForErrs({ error: parsedResponse?.error, message: parsedResponse?.message })

  return parsedResponse
}

export async function getBenchmarkPerformanceScoreCardTotal({ accessToken, data }) {
  const url = `${marketBenchmarkingApiUrl}${v1}/benchmarks/performance-score-card-total`

  const response = await customFetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + accessToken },
    body: JSON.stringify(data),
  })
  const parsedResponse = await getResponseParsed(response)

  return parsedResponse
}

export async function getBenchmarkBestInClassAnalysis({ accessToken, bestInClassAnalysisParams }) {
  const url = api_url_root + 'benchmarks/best-in-class-analysis'

  const response = await customFetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + accessToken },
    body: JSON.stringify(bestInClassAnalysisParams),
  })
  const parsedResponse = await response.json()
  validateParsedJsonForErrs({ error: parsedResponse?.error, message: parsedResponse?.message })

  return parsedResponse
}

import React from 'react'
import { UNIT_OF_MEASURE_OPTION_VALUE } from '../../FilterContext.constants'
import { useToastContext } from '../../../ToastContext/useToastContext'
import { TOAST_IDS } from '../../../ToastContext/constants'
import { sessionItems } from '../../../../utils/sessionStoreManage'
import { useSessionStorage } from '../../../../hooks/useSessionStorage/useSessionStorage'

/**
 * Custom hook for managing the unit of measurement filter in the FilterContext.
 * @returns {Object} An object containing the current unitOfMeasurement value and the updateUnitOfMeasurement function.
 * @property {string} unitOfMeasurement - The current unit of measurement value.
 * @property {Function} updateUnitOfMeasurement - The function for updating the unit of measurement value.
 */
export const useUnitOfMeasurementFilter = () => {
  const { addToast } = useToastContext()
  const { storageValue, setStorageValue } = useSessionStorage(sessionItems.UNIT_MEASUREMENT)
  const initialUnitOfMeasurement = storageValue || UNIT_OF_MEASURE_OPTION_VALUE.per_provider
  const [unitOfMeasurement, setUnitOfMeasurement] = React.useState(initialUnitOfMeasurement)

  const updateUnitOfMeasurement = React.useCallback(
    newSelectedUnitOfMeasurement => {
      /**
       * This assumes we never want to unset the selected unit of measurement.  If we later add this feature,
       * then we will need to include undefined in the array: ([...UNIT_OF_MEASURE_OPTION_VALUE, undefined]).includes(...)
       */
      if (!Object.values(UNIT_OF_MEASURE_OPTION_VALUE).includes(newSelectedUnitOfMeasurement)) {
        return addToast({
          toastId: TOAST_IDS.newSelectedUnitOfMeasurementError,
          variant: 'error',
          msg: `Error updating the selected unit of measurement filter.`,
        })
      }
      setStorageValue(newSelectedUnitOfMeasurement)
      setUnitOfMeasurement(newSelectedUnitOfMeasurement)
    },
    [addToast, setStorageValue],
  )

  return {
    unitOfMeasurement,
    updateUnitOfMeasurement,
  }
}

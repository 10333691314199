import React from 'react'
import PropTypes from 'prop-types'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import LoadingSkeleton from '../../../../../../components/LoadingSkeleton'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)
const IE_TO_VISIT_RATIO = 'IE to Visit Ratio'

/**
 * Renders the Clinic Performance Graph component.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.isLoading - Indicates if the data is loading.
 * @param {string} props.selectedBenchmark - The selected benchmark.
 * @param {Object} props.data - The data for the graph.
 * @param {Object} props.fallbackData - The fallback data for the graph if data is undefined.
 * @param {string[]} props.data.labels - The labels for the graph.
 * @param {Object[]} props.data.datasets - The datasets for the graph.
 * @param {string} props.data.datasets[].backgroundColor - The background color for the dataset.
 * @param {string} props.data.datasets[].borderColor - The border color for the dataset.
 * @param {number[]} props.data.datasets[].data - The data for the dataset.
 * @param {string} props.data.datasets[].label - The label for the dataset.
 * @param {number} props.data.datasets[].year - The year for the dataset.
 * @returns {JSX.Element} The rendered Clinic Performance Graph component.
 */
export function ClinicPerformanceGraph({ selectedBenchmark, data: rawData, fallbackData, isLoading }) {
  const data = Array.isArray(rawData?.datasets) && Array.isArray(rawData?.labels) ? rawData : fallbackData
  const options = React.useMemo(
    () => ({
      scales: {
        y: {
          title: {
            display: true,
            text: selectedBenchmark === IE_TO_VISIT_RATIO ? selectedBenchmark + ' (%)' : selectedBenchmark,
            font: {
              family: 'Lato', // Add your font here to change the font of your y axis
            },
          },
          beginAtZero: true,
          ticks: {
            font: {
              family: 'Lato', // Add your font here to change the font of your y axis
            },
            callback: function (value) {
              return selectedBenchmark === IE_TO_VISIT_RATIO ? (value * 100).toFixed(0) : value
            },
          },
        },
        x: {
          grid: {
            display: false,
          },
          ticks: {
            font: {
              family: 'Lato', // Add your font here to change the font of your y axis
            },
          },
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'bottom',
          font: {
            family: 'Lato', // Add your font here to change the font of your y axis
          },
        },
        title: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              const { dataset, raw } = tooltipItem
              const label = dataset?.label || ''
              const value =
                selectedBenchmark === IE_TO_VISIT_RATIO ? (raw * 100).toFixed(1) + '%' : tooltipItem.raw.toFixed(1)

              return `${label}: ${value}`
            },
          },
        },
      },
    }),
    [selectedBenchmark],
  )

  return (
    <>
      {isLoading ? (
        <LoadingSkeleton variant="rectangular" height="444px" data-testid="loading-clinic-performance-graph" />
      ) : (
        <Line options={options} data={data} height="444px" data-testid="clinic-performance-line-graph" />
      )}
    </>
  )
}

ClinicPerformanceGraph.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  selectedBenchmark: PropTypes.string.isRequired,
  data: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string),
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        backgroundColor: PropTypes.string,
        borderColor: PropTypes.string,
        data: PropTypes.arrayOf(PropTypes.number),
        label: PropTypes.string,
        year: PropTypes.number,
      }).isRequired,
    ),
  }),
  fallbackData: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string).isRequired,
    datasets: PropTypes.array.isRequired, // will be an empty array
  }),
}

import * as React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import EditScenarioForm, {
  editScenarioValidationSchema,
  initialTouchedForEditScenarioForm,
} from '../../../../../forms/EditScenarioForm'
import { Formik } from 'formik'
import { Save } from '@mui/icons-material'
import BaseModalContent from '../../../../../components/modals/BaseModalContent'
import StyledFormDialog from '../../../../../components/modals/FormModal/StyledFormDialog'
import { TOAST_IDS } from '../../../../../context/ToastContext/constants'
import { useToastContext } from '../../../../../context/ToastContext/useToastContext'
import { useAuthContext } from '../../../../../context/AuthContext/useAuthContext'
import { runBenchmark, updateScenario } from '../../../../../services/apis'
import { ApiError } from '../../../../../utils/ApiError'
import ErrorMessage from '../../../../../components/ErrorMessage'
import { useFilterContext } from '../../../../../context/FilterContext/useFilterContext'
import { useFetchScenarioParams } from '../../../../../context/SidebarContext/hooks/useFetchScenarioParams/useFetchScenarioParams'
import { parseInValuesForEditScenario } from '../../../../../forms/EditScenarioForm/parseInValuesForEditScenario'
import { parseOutValuesForEditScenario } from '../../../../../forms/EditScenarioForm/parseOutValuesForEditScenario'
import { initialStatusForEditScenarioForm } from '../../../../../forms/EditScenarioForm/EditScenarioForm'
import { useSidebarContext } from '../../../../../context/SidebarContext/useSidebarContext'

/**
 * We use a no-op function to handle the onClose event for the modal
 * to require the member to either click the "X" button or to click the "Cancel" button.
 * With this function, users will not be able to click "ESC" key to exit or click outside the modal to exit.
 *
 */
const blockExitFromModal = () => undefined

/**
 * Renders the Edit Scenario Modal & Form.
 * @param {Object} props The component props.
 * @param {boolean} props.isEditDialogOpen Whether the modal is open.
 * @param {Function} props.handleCloseModal The function to close the modal.
 * @param {string} props.selectedScenarioid The selected scenario id.
 * @returns {React.ReactNode} The Edit Scenario component.
 */
export function EditScenario({ isEditDialogOpen, handleCloseModal, selectedScenarioid }) {
  const { addToast } = useToastContext()
  const { accessToken } = useAuthContext()
  const { selectedScenario: selectedFilterBarScenario, refetchScenarios } = useFilterContext()

  const { refetchScenarioParams } = useSidebarContext()

  const [formLevelError, setFormLevelError] = React.useState(undefined)

  const {
    isLoading: isLoadingFetchScenarioParams,
    scenarioParamsWithMetadata: scenarioParamsWithMetadata,
    fetchScenarioParams,
    scenarioParamStoredValue,
    fetchStoredScenarioParams,
  } = useFetchScenarioParams({ defaultIsLoading: true })

  React.useEffect(() => {
    if (!accessToken || !selectedScenarioid) return

    fetchScenarioParams({
      accessToken,
      scenarioId: selectedScenarioid,
      errorToastId: TOAST_IDS.getScenarioParamsErrorForSidebarContext,
    })
  }, [fetchScenarioParams, selectedScenarioid, accessToken])

  React.useEffect(() => {
    if (!scenarioParamStoredValue && !selectedScenarioid) return

    fetchStoredScenarioParams({ scenarioId: selectedScenarioid })
  }, [fetchStoredScenarioParams, scenarioParamStoredValue, selectedScenarioid])

  const handleSubmit = async (formikValues, formikActions) => {
    setFormLevelError(undefined)
    const { setFieldError } = formikActions
    try {
      const payload = parseOutValuesForEditScenario({ formikValues, scenarioId: selectedScenarioid })
      const updatedScenario = await updateScenario({ accessToken, data: payload })
      const scenarioTitleFromResponse = updatedScenario?.scenario_parameters?.scenario_title || ''
      // runBenchmark is required to update the cache tables in the backend so the content is updated
      await runBenchmark(accessToken, { scenarioid: selectedScenarioid })

      /**
       * We only need to update the scenario params if the currently scenario being updated matches
       * the selected scenario on the filter bar so that the update is made to the sidebar.
       */
      const shouldRefetchScenarioParams = selectedFilterBarScenario.scenarioid === selectedScenarioid

      await Promise.all([
        refetchScenarios({
          accessToken,
          errorToastId: TOAST_IDS.refetchScenariosErrorForEditScenario,
          nextSelectedScenarioId: selectedFilterBarScenario.scenarioid, // maintain filterbar selection
        }),
        shouldRefetchScenarioParams
          ? refetchScenarioParams({
              accessToken,
              scenarioId: selectedScenarioid,
              errorToastId: TOAST_IDS.refetchScenarioParamsErrForEditScenario,
            })
          : Promise.resolve(),
      ])

      addToast({
        toastId: TOAST_IDS.editScenarioSuccess,
        variant: 'success',
        msg: `Scenario "${scenarioTitleFromResponse}" was updated successfully.`,
      })

      handleCloseModal()
    } catch (error) {
      if (error instanceof ApiError) {
        if (['scenario_title', 'years'].includes(error?.errorTarget)) {
          return setFieldError(error.errorTarget, error?.message)
        }
      }

      setFormLevelError(error.message)
    }
  }

  const initialValuesForEditScenarioForm = parseInValuesForEditScenario(scenarioParamsWithMetadata)

  return (
    <>
      {/* 
          Note that StyledFormDialog does not render unless isEditDialogOpen is true.
          Then, StyledFormDialog will render as a React portal. 
      */}
      <StyledFormDialog onClose={blockExitFromModal} open={isEditDialogOpen} data-testid="edit-scenario-modal">
        <Formik
          initialValues={initialValuesForEditScenarioForm}
          initialTouched={initialTouchedForEditScenarioForm}
          initialStatus={initialStatusForEditScenarioForm}
          onSubmit={handleSubmit}
          validationSchema={editScenarioValidationSchema}
          enableReinitialize={true} // allows the form to update initialValues when the scenarioParamsWithMetadata changes
        >
          {formikProps => {
            return (
              <BaseModalContent
                handleClickCloseButton={handleCloseModal}
                hasContentDividers={true}
                modalTitle="Edit Scenario"
                closeIconBtnAttributes={{
                  'data-testid': 'edit-scenario-close-button',
                  disabled: formikProps.isSubmitting,
                }}
                renderModalFooter={() => (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      paddingX: 1,
                      marginY: 1,
                      height: '100%',
                      width: '100%',
                    }}
                  >
                    {formLevelError && (
                      <ErrorMessage sx={{ fontSize: { xs: '12px', sm: '14px' }, paddingRight: 1 }}>
                        {formLevelError}
                      </ErrorMessage>
                    )}
                    <Box sx={{ minWidth: '240px' }}>
                      <Button
                        variant="outlined"
                        onClick={handleCloseModal}
                        sx={{ marginRight: 1 }}
                        color="error"
                        disabled={formikProps.isSubmitting}
                        data-testid="edit-scenario-cancel-button"
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="outlined"
                        startIcon={<Save />}
                        onClick={formikProps.handleSubmit}
                        disabled={formikProps.isSubmitting}
                        data-testid="edit-scenario-save-button"
                      >
                        Update Scenario
                      </Button>
                    </Box>
                  </Box>
                )}
              >
                <EditScenarioForm
                  {...formikProps}
                  isLoading={isLoadingFetchScenarioParams}
                  shouldDisplayClinicTable={true}
                />
              </BaseModalContent>
            )
          }}
        </Formik>
      </StyledFormDialog>
    </>
  )
}

EditScenario.propTypes = {
  isEditDialogOpen: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  selectedScenarioid: PropTypes.string,
}

import React from 'react'
import { UNIT_OF_TIME_OPTION_VALUE } from '../../FilterContext.constants'
import { useToastContext } from '../../../ToastContext/useToastContext'
import { TOAST_IDS } from '../../../ToastContext/constants'
import { sessionItems } from '../../../../utils/sessionStoreManage'
import { useSessionStorage } from '../../../../hooks/useSessionStorage/useSessionStorage'

/**
 * Custom hook for managing the unit of time filter in the FilterContext.
 * @returns {Object} An object containing the current unitOfTime value and the updateUnitOfTime function.
 * @property {string} unitOfTime - The current unit of time value.
 * @property {Function} updateUnitOfTime - The function for updating the unit of time value.
 */
export const useUnitOfTimeFilter = () => {
  const { addToast } = useToastContext()
  const { storageValue, setStorageValue } = useSessionStorage(sessionItems.UNIT_TIME)
  const initialUnitOfTime = storageValue || UNIT_OF_TIME_OPTION_VALUE.month
  const [unitOfTime, setUnitOfTime] = React.useState(initialUnitOfTime)

  const updateUnitOfTime = React.useCallback(
    /**
     * Updates the selected unit of time filter.
     * @param {string} newUnitOfTime - The new unit of time value.
     */
    newUnitOfTime => {
      /**
       * This assumes we never want to unset the selected unit of time.  If we later add this feature,
       * then we will need to include undefined in the array: ([...UNIT_OF_TIME_OPTION_VALUE, undefined]).includes(...)
       */
      if (!Object.values(UNIT_OF_TIME_OPTION_VALUE).includes(newUnitOfTime)) {
        return addToast({
          toastId: TOAST_IDS.newSelectedUnitOfTimeError,
          variant: 'error',
          msg: `Error updating the selected unit of time filter`,
        })
      }
      setStorageValue(newUnitOfTime)
      setUnitOfTime(newUnitOfTime)
    },
    [addToast, setStorageValue],
  )

  return {
    unitOfTime,
    updateUnitOfTime,
  }
}

import React from 'react'
import { useToastContext } from '../../../ToastContext/useToastContext'
import { listScenarios } from '../../../../services/apis'
import { checkShouldSetDefaultScenario } from './checkShouldSetDefaultScenario'
import { validateScenario } from './validateScenario'
import { sessionItems } from '../../../../utils/sessionStoreManage'
import { useSessionStorage } from '../../../../hooks/useSessionStorage/useSessionStorage'

/**
 * Custom hook for managing scenario filtering.
 * @param {Object} options - The options for the hook.
 * @param {boolean} options.defaultIsLoading - The default value for the isLoading state.
 * @returns {Object} - An object containing the hook's state and functions.
 * @property {boolean} isLoading - Whether the scenarios are being loaded.
 * @property {Error} error - The error that occurred during loading.
 * @property {Array.<Scenario>} scenarios - The list of scenarios.
 * @property {Scenario} selectedScenario - The selected scenario.
 * @property {Function} updateSelectedScenario - The function for updating the selected scenario.
 * @property {Function} fetchScenarios - The function for fetching the scenarios.
 */
export const useScenarioFilter = ({ defaultIsLoading = false } = { defaultIsLoading: false }) => {
  const { addToast } = useToastContext()
  const { setStorageValue, storageValue } = useSessionStorage(sessionItems.SCENARIO_FILTER)

  /**
   * @type {Array.<{id: string, scenarioid: string, created_by_userid: string, scenario_title: string, scenario_description: string|null, create_date: string, is_temp: boolean, cached_benchmark_table: string, default_scenario_flag: boolean, portfolio_title: string}>}
   * An array of scenario objects. Each scenario object contains the following properties:
   * @property {string} id - The ID of the scenario.
   * @property {string} scenarioid - The unique identifier of the scenario.
   * @property {string} created_by_userid - The user ID of the creator of the scenario.
   * @property {string} scenario_title - The title of the scenario.
   * @property {string|null} scenario_description - The description of the scenario. Can be null.
   * @property {string} create_date - The creation date of the scenario.
   * @property {boolean} is_temp - Whether the scenario is temporary.
   * @property {string} cached_benchmark_table - The name of the cached benchmark table.
   * @property {boolean} default_scenario_flag - Whether the scenario is the default one.
   * @property {string} portfolio_title - The title of the portfolio that the scenario belongs to.
   */

  const [scenarios, setScenarios] = React.useState([])
  const [selectedScenario, setSelectedScenario] = React.useState()
  const [isLoading, setIsLoading] = React.useState(defaultIsLoading)
  const [error, setError] = React.useState(null)

  /**
   * Fetches the list of scenarios.
   * @param {Object} options - The options for fetching scenarios.
   * @param {string} options.accessToken - The access token for authentication.
   * @param {string} options.errorToastId - The ID of the error toast.
   * @param {string} options.nextSelectedScenarioId - The ID of the scenario to be selected after the fetch.
   * @returns {Array.<Scenario>} - The list of scenarios.
   * @throws {Error} - If accessToken or errorToastId is missing.
   */
  const fetchScenarios = React.useCallback(
    async ({ accessToken, errorToastId, nextSelectedScenarioId }) => {
      try {
        if (!accessToken || !errorToastId) {
          throw new Error('accessToken and errorToastId are required')
        }
        const res = await listScenarios(accessToken)
        const scenarioList = res?.data?.scenario_list || []
        setScenarios(scenarioList)

        const shouldSetDefaultScenario = checkShouldSetDefaultScenario({
          scenarios: scenarioList,
          selectedScenarioId: selectedScenario?.id,
          nextSelectedScenarioId,
        })

        const storedFilterScenario = storageValue || '{}'
        const filterScenario = {}

        if (shouldSetDefaultScenario) {
          const defaultScenario =
            storedFilterScenario.selectedScenario ||
            scenarioList?.find(scenario => scenario.default_scenario_flag === true) ||
            scenarioList[0]

          filterScenario.selectedScenario = defaultScenario
          setSelectedScenario(defaultScenario)
        }

        if (nextSelectedScenarioId) {
          const newSelectedScenario =
            storedFilterScenario.nextSelectedScenario ||
            scenarioList?.find(scenario => scenario.scenarioid === nextSelectedScenarioId) ||
            scenarioList[0]

          filterScenario.nextSelectedScenario = newSelectedScenario
          setSelectedScenario(newSelectedScenario)
        }
        setStorageValue(filterScenario)

        return scenarioList
      } catch (e) {
        console.error(e)
        setError(e)
        addToast({
          toastId: errorToastId,
          variant: 'error',
          msg: `Error loading scenario list - ${e?.message || 'unknown error'}`,
        })
        setScenarios([])
      } finally {
        setIsLoading(false)
      }
    },
    [addToast, selectedScenario?.id, setStorageValue, storageValue],
  )

  /**
   * Updates the selected scenario and handles validation errors.
   *
   * @param {Object} options - The options for updating the selected scenario.
   * @param {Object} options.scenario - The new scenario to be selected.
   * @param {string} options.errorToastId - The ID of the error toast.
   * @returns {void}
   * @throws {Error} - If the scenario is invalid.
   */
  const updateSelectedScenario = React.useCallback(
    ({ scenario, errorToastId }) => {
      try {
        validateScenario(scenario)
        setStorageValue({ selectedScenario: scenario })
        setSelectedScenario(scenario)
      } catch (e) {
        addToast({
          toastId: errorToastId,
          variant: 'error',
          msg: `Error updating the selected scenario - ${e?.message || 'unknown error'}`,
        })
      }
    },
    [addToast, setStorageValue],
  )

  return {
    isLoading,
    error,
    scenarios,
    selectedScenario,
    updateSelectedScenario,
    fetchScenarios,
  }
}

import React from 'react'
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useVirtualizer } from '@tanstack/react-virtual'
import { Card } from '@mui/material'
import LoadingSkeleton from '../../LoadingSkeleton'
import { DEFAULT_ROW_HEIGHT } from '../Table.constants'
import SortableTableHeaderCell from '../tableComponents/SortableTableHeaderCell'
import { ELLIPSIS_STYLES } from '../Table.constants'

export function InfiniteScrollTable({
  columns,
  data,
  fetchNextPage,
  isFetching,
  isLoading,
  sorting,
  setSorting,
  columnVisibility,
  setColumnVisibility,
  enableSelectableRows = false,
  rowSelection,
  setRowSelection,
  tableHeight,
  renderFetchingMsg,
  noRecordsOverlay,
  rowHeight = DEFAULT_ROW_HEIGHT,
  fetchMoreThreshold,
  handleRowClick,
}) {
  //we need a reference to the scrolling element for logic down below
  const tableContainerRef = React.useRef(null)

  // flatten the array of arrays from the useInfiniteQuery hook
  const flatData = React.useMemo(() => data?.pages?.flatMap(page => page.data) ?? [], [data])

  const totalDBRowCount = data?.pages?.[0]?.meta?.totalRowCount ?? 0
  const totalFetched = flatData.length

  // called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = React.useCallback(
    containerRefElement => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement
        //once the user has scrolled within (fetchMoreThreshold)px of the bottom of the table, fetch more data if we can
        if (
          scrollHeight - scrollTop - clientHeight < fetchMoreThreshold &&
          !isFetching &&
          totalFetched < totalDBRowCount
        ) {
          fetchNextPage()
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched, totalDBRowCount, fetchMoreThreshold],
  )

  //a check on mount and after a fetch to see if the table is already scrolled to the bottom and immediately needs to fetch more data
  React.useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current)
  }, [fetchMoreOnBottomReached])

  const table = useReactTable({
    data: flatData,
    columns,
    state: {
      rowSelection,
      sorting,
      columnVisibility,
    },
    getRowId: row => row?.clinic_id,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableRowSelection: enableSelectableRows,
    onRowSelectionChange: state => {
      setRowSelection({})
      setRowSelection(state)
    },
    onColumnVisibilityChange: setColumnVisibility,
    manualSorting: true, // manual sorting is true because HTTP includes sort params
  })

  //scroll to top of table when sorting changes
  const handleSortingChange = updater => {
    setSorting(updater)
    if (table?.getRowModel()?.rows?.length) {
      rowVirtualizer.scrollToIndex?.(0)
    }
  }

  //since this table option is derived from table row model state, we're using the table.setOptions utility
  table.setOptions(prev => ({
    ...prev,
    onSortingChange: handleSortingChange,
  }))

  const { rows } = table.getRowModel()

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => DEFAULT_ROW_HEIGHT, //estimate row height for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef.current,
    //measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== 'undefined' && global.navigator.userAgent.indexOf('Firefox') === -1
        ? element => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  })

  const noRecordsFound = !data?.pages?.[0]?.data?.length

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <TableContainer
        ref={tableContainerRef}
        component={Card}
        onScroll={e => fetchMoreOnBottomReached(e.target)}
        sx={{
          height: tableHeight, //should be a fixed height
          overflow: 'auto', //our scrollable table container
          position: 'relative', //needed for sticky header
          width: '100%',
        }}
      >
        {/* Even though we're still using sematic table tags, we must use CSS grid and flexbox for dynamic row heights */}
        <Table component="div" sx={{ display: 'flex', flexDirection: 'column' }}>
          <TableHead
            component="div"
            role="presentation"
            sx={{
              background: '#FFF',
              position: 'sticky',
              top: 0,
              width: '100%',
              zIndex: 2,
            }}
          >
            {table.getHeaderGroups().map((headerGroup, index) => (
              <TableRow
                key={headerGroup.id}
                aria-rowindex={index + 1}
                component="div"
                role="rowgroup"
                sx={{ display: 'flex', alignItems: 'center', width: '100%', height: rowHeight }}
              >
                {headerGroup.headers.map((header, index) => {
                  return (
                    <SortableTableHeaderCell
                      key={header.id}
                      header={header}
                      colIndex={index + 1}
                      rowHeight={rowHeight}
                    />
                  )
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody
            component="div"
            sx={{
              display: 'flex',
              height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
              position: 'relative', //needed for absolute positioning of rows
            }}
          >
            {isLoading && (
              <Box
                component="div"
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  height: tableHeight - rowHeight, //subtract header height
                  justifyContent: 'center',
                  position: 'absolute',
                  transform: `translateY(0px)`, //this should always be a `style` as it changes on scroll
                  width: '100%',
                }}
              >
                <LoadingSkeleton variant="rectangular" width="100%" height="100%" />
              </Box>
            )}

            {!isLoading && !isFetching && noRecordsFound && (
              <Box
                component="div"
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  height: tableHeight - rowHeight,
                  justifyContent: 'center',
                  position: 'absolute',
                  transform: `translateY(0px)`, //this should always be a `style` as it changes on scroll
                  width: '100%',
                }}
              >
                {noRecordsOverlay ? noRecordsOverlay : <div>No records found.</div>}
              </Box>
            )}

            {rowVirtualizer.getVirtualItems().map(virtualRow => {
              const row = rows[virtualRow.index]
              const selectableColor =
                enableSelectableRows && row.getIsSelected() ? 'rgb(21, 121, 221, 0.3) !important' : 'inherit'
              return (
                <TableRow
                  component="div"
                  data-index={virtualRow.index} //needed for dynamic row height measurement
                  ref={node => rowVirtualizer.measureElement(node)} //measure dynamic row height
                  key={row.id}
                  selected={enableSelectableRows ? row.getIsSelected() : false}
                  sx={{
                    alignItems: 'center',
                    borderBottom: '1px solid #E0E0E0',
                    display: 'flex',
                    height: rowHeight,
                    backgroundColor: selectableColor,
                    maxWidth: '100%',
                    position: 'absolute',
                    cursor: enableSelectableRows ? 'pointer' : 'inherit',
                    transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                    width: '100%',
                    '&:hover': {
                      background: 'rgba(0, 0, 0, 0.06)',
                    },
                  }}
                  onClick={() => handleRowClick(row)}
                >
                  {row.getVisibleCells().map(cell => {
                    return (
                      <TableCell
                        key={cell.id}
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          flex: cell.column.columnDef.flex || 1,
                          maxHeight: rowHeight,
                          maxWidth: '100%',
                          minHeight: rowHeight,
                          paddingX: 1,
                          width: cell.column.getSize(),
                          ...ELLIPSIS_STYLES,
                        }}
                        component="div"
                        role="tablecell"
                        onClick={() => enableSelectableRows && row.toggleSelected()}
                      >
                        <Box sx={ELLIPSIS_STYLES}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Box>
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {renderFetchingMsg && (
        <Box sx={{ height: 26, visibility: isFetching ? 'visible' : 'hidden' }}>{renderFetchingMsg(table)}</Box>
      )}
    </Box>
  )
}

import React from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import DebouncedTextField from '../../components/DebouncedTextField'
import { useAppContext } from '../../context/AppContext/useAppContext'
import { formikPropTypes } from '../formikPropTypes'
import { FormControl, FormHelperText, FormLabel, InputLabel, MenuItem, Select } from '@mui/material'
import { useSidebarContext } from '../../context/SidebarContext/useSidebarContext'
import SliderStyle from '../../components/SliderStyle'
import LoadingSkeleton from '../../components/LoadingSkeleton'
import CheckboxGroup from '../../components/CheckboxGroup'
import { useDebouncedWidth } from '../../hooks/useDebouncedWidth'
import { StaticScrollTable } from '../../components/Table'
import {
  SORT_COLUMN_BY_ALPHANUMERIC_INSENSITIVE,
  SORT_COLUMN_BY_ALPHANUMERIC_SENSITIVE,
  composeSortBySelectedItems,
} from '../../components/Table/tableSort'
import { sessionItems } from '../../utils/sessionStoreManage'
import { useSessionStorage } from '../../hooks/useSessionStorage/useSessionStorage'

const ERROR_MESSAGE_CONTAINER_STYLES = {
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  minHeight: '26px',
}

export const initialStatusForEditScenarioForm = {
  shouldReset: true,
}

export const initialTouchedForEditScenarioForm = {
  clinic_size: false,
  portfolioid: false,
  scenario_title: false,
  therapist_type: false,
  therapy_type: false,
  visit_type: false,
  years: false,
}

export const editScenarioValidationSchema = yup.object({
  clinic_size: yup
    .array()
    .required('Clinic size is required.')
    .test('min-and-max-selected', 'Clinic size must include a minimum and maximum value.', value => value.length === 2)
    .test(
      'min-less-than-or-equal-to-max',
      'Minimum clinic size must be less than the maximum clinic size.',
      value => value[0] <= value[1],
    )
    .test('min-threshold', 'Minimum clinic size must be greater than 0.', value => value[0] > 0)
    .test('max-threshold', 'Maximum clinic size must be less than 75.', value => value[1] <= 75),
  portfolioid: yup
    .string()
    .required('Portfolio is required.')
    .test('no-whitespace-portfolio', 'Portfolio is required.', value => value && value.trim().length > 0),
  scenario_title: yup
    .string()
    .required('Scenario name is required.')
    .test(
      'no-whitespace-scenario',
      'A scenario name cannot consist of only spaces.',
      value => value && value.trim().length > 0,
    ),
  therapist_type: yup.array().required('Therapist type is required.'),
  therapy_type: yup.string().required('Therapy type is required.'),
  visit_type: yup.string().required('Visit type is required.'),
  years: yup
    .array()
    .required('Year is a required field.')
    .test('non-empty', 'At least 1 year must be selected.', value => value?.length > 0),
})

const THERAPY_TYPE_OPTIONS = [
  {
    value: 'all',
    name: 'All',
  },
]

/**
 * EditScenarioForm component represents a form for creating a scenario.
 *
 * ****IMPORTANT****
 * DOCUMENTED BELOW IS ONLY A SUBSET OF ALL THE FORMIK PROPS PASSED TO THE COMPONENT
 * (SEE PROP-TYPES BELOW FOR FULL LIST)
 * ****IMPORTANT***
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.errors - The formik errors object.
 * @param {Function} props.handleBlur - The formik handleBlur function.
 * @param {Function} props.handleChange - The formik handleChange function.
 * @param {Function} props.handleFocus - The formik handleFocus function.
 * @param {Function} props.handleSubmit - The formik handleSubmit function.
 * @param {boolean} props.isSubmitting - The formik isSubmitting state.
 * @param {boolean} [props.isUnitTest=false] - Determines if the component is being tested.
 * @param {Function} props.setFieldTouched - The formik setFieldTouched function.
 * @param {Function} props.setFieldValue - The formik setFieldValue function.
 * @param {Object} props.touched - The formik touched object.
 * @param {Object} props.values - The formik values object.
 * @returns {JSX.Element} The rendered EditScenarioForm component.
 */
export const EditScenarioForm = ({
  isLoading,
  isUnitTest,
  shouldDisplayClinicTable,
  renderFormButtons,
  ...formikProps
}) => {
  const {
    errors,
    handleBlur,
    handleChange,
    handleFocus,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    touched,
    values,
    initialValues,
    status,
    setStatus,
  } = formikProps

  const { clinicsSorted, isLoadingFetchClinics, menuOptions } = useAppContext()
  const { portfoliosSorted, isLoadingFetchPortfolios, reStorageValue } = useSidebarContext()
  const { setSessionStorageItem } = useSessionStorage(sessionItems.SCENARIO_PARAMS)

  const formRef = React.useRef(null)
  const formWidth = useDebouncedWidth(formRef)

  const visitTypeOptionMap = React.useMemo(() => {
    const visitTypeOptions =
      menuOptions
        ?.filter(opt => opt.menu === 'visit_type')
        ?.map(filteredOpt => ({ value: filteredOpt.option_id, name: filteredOpt.option_value })) || []

    return [...visitTypeOptions]?.sort((a, b) => a?.name?.localeCompare(b?.name)) || []
  }, [menuOptions])
  const yearOptionMap = React.useMemo(() => {
    if (!menuOptions) return null
    return menuOptions
      ?.filter(opt => opt.menu === 'year')
      ?.reduce((acc, menuItem) => {
        return [
          ...acc,
          {
            label: menuItem.option_id,
            value: menuItem.option_value,
          },
        ]
      }, [])
  }, [menuOptions])

  const therapistTypeOptionMap = React.useMemo(() => {
    if (!menuOptions) return null
    return menuOptions
      ?.filter(opt => opt.menu === 'therapist_type')
      ?.reduce((acc, menuItem) => {
        return [
          ...acc,
          {
            value: menuItem.option_id,
            label: menuItem.option_value,
          },
        ]
      }, [])
  }, [menuOptions])

  const selectedPortfolioClinics = React.useMemo(
    () => portfoliosSorted?.find(portfolio => portfolio.portfolioid === values.portfolioid)?.list_of_clinics || [],
    [portfoliosSorted, values.portfolioid],
  )

  const clinicsSortedWithIdAndChecked = React.useMemo(() => {
    return clinicsSorted.map(clinic => {
      return {
        id: clinic.clinicid,
        isChecked: selectedPortfolioClinics.includes(clinic.clinicid),
        ...clinic,
      }
    })
  }, [clinicsSorted, selectedPortfolioClinics])

  const columns = React.useMemo(() => {
    const selectedValuesList = Array.isArray(selectedPortfolioClinics) ? selectedPortfolioClinics : []
    const sortBySelectedClinicsFn = composeSortBySelectedItems({
      selectedValuesList,
      targetPropertyName: 'clinicid',
    })
    return [
      {
        id: 'id',
        header: 'Clinic ID', // The text to display in the header cell
        accessorFn: row => row.clinicid, // How the data should be accessed from the row object
        cell: info => info.getValue(), // How the data should be displayed in the cell
        enableSorting: true,
        sortDescFirst: false,
        sortingFn: SORT_COLUMN_BY_ALPHANUMERIC_INSENSITIVE,
        flex: 1, // To style width of column -> This is the shorthand for flex-grow, flex-shrink and flex-basis combined (see https://css-tricks.com/snippets/css/a-guide-to-flexbox/)
      },
      {
        id: 'clinic_name',
        header: 'Clinic Name',
        accessorFn: row => row.clinic_name,
        cell: info => info.getValue(),
        enableSorting: true,
        sortDescFirst: false,
        sortingFn: SORT_COLUMN_BY_ALPHANUMERIC_SENSITIVE,
        flex: 3,
      },
      {
        id: 'select',
        accessorKey: 'Selected',
        header: 'Selected', // We do this so that the padding matches the icon button padding for alignment purposes
        enableSorting: true,
        sortDescFirst: false,
        sortingFn: sortBySelectedClinicsFn,
        flex: 1,
        cell: params => {
          const isChecked = selectedPortfolioClinics.includes(params.row.original.clinicid)
          return (
            <Checkbox
              name="clinics"
              checked={isChecked}
              inputProps={{
                'aria-label': params.row.original.clinic_name,
                'data-testid': `clinic-checkbox-${params.row.original.clinicid}`,
                'data-test-is-checked': `${isChecked}`,
              }}
              disabled={true} // read only
            />
          )
        },
      },
    ]
  }, [selectedPortfolioClinics])

  const compareScenarioWithSession = (value, name) => {
    const newStoredScenarioParams = reStorageValue

    if (newStoredScenarioParams && Array.isArray(newStoredScenarioParams.scenario_parameters)) {
      newStoredScenarioParams.scenario_parameters.forEach(param => {
        if (param && typeof param === 'object' && param.param === name) {
          param.param_value = value
          setSessionStorageItem(newStoredScenarioParams)
        }
      })
    } else {
      console.error('Invalid scenario parameters or storage value.')
    }
  }

  const handleCheckboxChange = option => e => {
    const name = e.target.name
    const selectedValues = values[name]
    const isChecked = values[name].includes(option.value)
    if (isChecked) {
      const optionsWithoutSelected = selectedValues.filter(selectedVal => selectedVal !== option.value)
      compareScenarioWithSession(optionsWithoutSelected, name)
      setFieldValue(name, optionsWithoutSelected)
    } else {
      const updatedOptions = [...selectedValues, option.value]
      compareScenarioWithSession(updatedOptions, name)
      setFieldValue(name, updatedOptions)
    }
  }

  const handleSlideChange = ({ lowerValue, upperValue }) => {
    setFieldValue('clinic_size', [lowerValue, upperValue])
    compareScenarioWithSession({ max: upperValue, min: lowerValue }, 'clinic_size')
  }

  const handleSelectChanges = e => {
    const { name, value } = e.target
    handleChange(e)
    compareScenarioWithSession([value], name)
  }

  React.useEffect(() => {
    if (status?.shouldReset) {
      setStatus({ shouldReset: false })
    }
  }, [status?.shouldReset, setStatus])

  /**
   * shouldDisplaySingleColumn take the styles in the sidebar whereas widths greater than this value take styles of modal
   */
  const shouldDisplaySingleColumn = formWidth < 550

  return (
    <form ref={formRef} onSubmit={handleSubmit} data-testid="edit-scenario-form" style={{ width: '100%' }}>
      {isLoading ? (
        <LoadingSkeleton
          variant="rectangular"
          width="100%"
          height={shouldDisplaySingleColumn ? '700px' : '570px'}
          data-testid="edit-scenario-loading-skeleton"
        />
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            width: '100%',
            height: '100%',
            paddingX: shouldDisplaySingleColumn ? 0 : 2,
          }}
        >
          <Box
            sx={{
              height: '100%',
              flex: `1 0 calc(100% /${shouldDisplaySingleColumn ? 1 : 2})`,
              paddingX: shouldDisplaySingleColumn ? 0 : 1,
              minWidth: '200px',
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-end',
                width: '100%',
                minHeight: shouldDisplaySingleColumn ? '100%' : '56px',
                height: '100%',
                paddingRight: {
                  xs: 0,
                  sm: 1,
                },
                paddingY: { xs: 1, sm: 0 },
              }}
            >
              <Typography
                sx={{
                  fontSize: shouldDisplaySingleColumn ? '16px' : '19px',
                  fontWeight: 700,
                  paddingRight: 2,
                }}
              >
                Scenario:{' '}
              </Typography>

              <DebouncedTextField
                id="scenario-name-input"
                name="scenario_title"
                placeholder="Enter name..."
                variant="standard"
                defaultInputValue={initialValues.scenario_title}
                shouldReset={status.shouldReset}
                handleInputChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                value={values.scenario_title}
                disabled={isSubmitting}
                error={!!touched.scenario_title && !!errors.scenario_title}
                InputProps={{
                  sx: {
                    'input::placeholder': {
                      color: !!touched.scenario_title && !!errors.scenario_title ? '#d32f2f' : 'currentColor',
                    },
                  },
                }}
                inputProps={{
                  'aria-describedby': 'scenario-name-helper-text',
                  'data-testid': 'scenario-name-input',
                }}
                sx={{
                  width: '100%',
                  maxWidth: '300px',
                  input: {
                    padding: '4px 0 2px',
                  },
                }}
                debounceIntervalInMs={300}
              />
            </Box>
            <Box sx={ERROR_MESSAGE_CONTAINER_STYLES}>
              {/**
               * We print the error message here instead of defining "helperText" as a prop for
               * DebouncedTextField to avoid jumpy behavior and to keep Portfolio aligned.
               */}
              {touched.scenario_title && errors.scenario_title && (
                <FormHelperText id="scenario-name-helper-text" error={true} data-testid="scenario-name-error-message">
                  {errors.scenario_title}
                </FormHelperText>
              )}
            </Box>

            <Box sx={{ width: '100%', height: '100%', paddingTop: shouldDisplaySingleColumn ? 1 : 3 }}>
              <Box sx={{ width: '100%', height: '100%' }}>
                <FormControl
                  error={touched.therapy_type && errors.therapy_type}
                  sx={{ width: '100%', maxWidth: '400px' }}
                >
                  <InputLabel id="therapy-type-label">Discipline Type</InputLabel>
                  <Select
                    id="therapy-type-select"
                    label="Discipline Type"
                    labelId="therapy-type-label"
                    name="therapy_type"
                    onChange={handleSelectChanges}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                    value={values.therapy_type}
                    variant="outlined"
                    sx={{ width: '100%', height: '100%', borderRadius: '5px' }}
                    inputProps={{ 'data-testid': 'therapy-type-select' }}
                  >
                    {THERAPY_TYPE_OPTIONS.map(option => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        data-testid="therapy-type-option"
                        data-test-option-value={option.value}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <Box sx={ERROR_MESSAGE_CONTAINER_STYLES}>
                    {touched.therapy_type && errors.therapy_type && (
                      <FormHelperText
                        id="therapy-type-helper-text"
                        error={true}
                        data-testid="therapy-type-error-message"
                      >
                        {errors.therapy_type}
                      </FormHelperText>
                    )}
                  </Box>
                </FormControl>
                <FormControl error={touched.visit_type && errors.visit_type} sx={{ width: '100%', maxWidth: '400px' }}>
                  <InputLabel id="visit-type-label">Visit Type</InputLabel>
                  <Select
                    id="visit-type-select"
                    label="Visit Type"
                    labelId="visit-type-label"
                    name="visit_type"
                    onChange={handleSelectChanges}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                    value={values.visit_type}
                    variant="outlined"
                    sx={{ width: '100%', height: '100%', borderRadius: '5px' }}
                    inputProps={{ 'data-testid': 'visit-type-select' }}
                  >
                    {visitTypeOptionMap?.map(option => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        data-testid="visit-type-option"
                        data-test-option-value={option.value}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <Box sx={ERROR_MESSAGE_CONTAINER_STYLES}>
                    {touched.visit_type && errors.visit_type && (
                      <FormHelperText
                        id="therapy-type-helper-text"
                        error={true}
                        data-testid="therapy-type-error-message"
                      >
                        {errors.visit_type}
                      </FormHelperText>
                    )}
                  </Box>
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  width: '100%',
                  height: '100%',
                  justifyContent: 'space-around',
                  maxWidth: '400px',
                }}
              >
                <Box
                  sx={{
                    height: '100%',
                    width: `${shouldDisplaySingleColumn ? '100%' : '30%'}`,
                  }}
                >
                  <FormControl component="fieldset" variant="standard" sx={{ width: '100%' }}>
                    <FormLabel component="legend">Years</FormLabel>
                    <CheckboxGroup
                      name="years"
                      columnCount={shouldDisplaySingleColumn ? 2 : 1}
                      options={yearOptionMap}
                      handleCheckboxChange={handleCheckboxChange}
                      verifyIsChecked={option => values.years.includes(option.value)}
                      size={shouldDisplaySingleColumn ? 'small' : 'medium'}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      disabled={isSubmitting}
                      sx={{
                        svg: {
                          backgroundColor:
                            !!touched.years && !!errors.years
                              ? 'rgba(211, 47, 47, 0.2) !important' // a light red border color when there is an error (using !important override so visible during hover)
                              : undefined,
                        },
                      }}
                    />
                    {shouldDisplaySingleColumn && (
                      <Box sx={ERROR_MESSAGE_CONTAINER_STYLES}>
                        {touched.years && errors.years && (
                          <FormHelperText id="years-helper-text" error={true} data-testid="years-error-message">
                            {errors.years}
                          </FormHelperText>
                        )}
                      </Box>
                    )}
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    height: '100%',
                    width: `${shouldDisplaySingleColumn ? '100%' : '65%'}`,
                  }}
                >
                  <FormControl sx={{ width: '100%' }}>
                    <FormLabel component="legend">Number of Providers</FormLabel>
                    {touched.clinic_size && errors.clinic_size && (
                      <FormHelperText
                        id="clinic-size-helper-text"
                        component={'span'}
                        error={true}
                        data-testid="clinic-size-error-message"
                        sx={{ marginX: '0 !important' }}
                      >
                        {errors.clinic_size}
                      </FormHelperText>
                    )}
                    <Typography sx={{ fontSize: '8pt', fontColor: '#656A6C' }}>
                      Providers : {values.clinic_size[0]} - {values.clinic_size[1]}
                    </Typography>
                    <SliderStyle
                      marks
                      name="clinic_size"
                      step={1}
                      max={75}
                      min={1}
                      defaultLowerValue={values.clinic_size[0]}
                      defaultUpperValue={values.clinic_size[1]}
                      handleChange={handleSlideChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      disabled={isSubmitting}
                      sx={{ width: '100%', padding: '30px 0px' }}
                    />
                  </FormControl>

                  <FormControl component="fieldset" variant="standard" sx={{ width: '100%' }}>
                    <FormLabel component="legend">Therapist Type</FormLabel>

                    <CheckboxGroup
                      columnCount={shouldDisplaySingleColumn ? 2 : 1}
                      options={therapistTypeOptionMap}
                      handleCheckboxChange={handleCheckboxChange}
                      name="therapist_type"
                      verifyIsChecked={option => values.therapist_type.includes(option.value)}
                      size={shouldDisplaySingleColumn ? 'small' : 'medium'}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      disabled={isSubmitting}
                    />

                    <Box sx={ERROR_MESSAGE_CONTAINER_STYLES}>
                      {touched.therapist_type && errors.therapist_type && (
                        <FormHelperText
                          id="therapist-type-helper-text"
                          error={true}
                          data-testid="therapist-type-error-message"
                        >
                          {errors.therapist_type}
                        </FormHelperText>
                      )}
                    </Box>
                  </FormControl>
                </Box>
              </Box>
              {!shouldDisplaySingleColumn && (
                <Box sx={{ ...ERROR_MESSAGE_CONTAINER_STYLES, justifyContent: 'flex-start' }}>
                  {touched.years && errors.years && (
                    <FormHelperText id="years-helper-text" error={true} data-testid="years-error-message">
                      {errors.years}
                    </FormHelperText>
                  )}
                </Box>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              height: '100%',
              width: '100%',
              flex: `1 0 calc(100% /${shouldDisplaySingleColumn ? 1 : 2})`,
              minWidth: '200px',
              paddingX: shouldDisplaySingleColumn ? 0 : 1,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                maxWidth: '400px',
                height: '100%',
                justifyContent: 'flex-end',
                paddingLeft: {
                  xs: 0,
                  md: 1,
                },
                paddingY: { xs: 1, sm: 0 },
              }}
            >
              <FormControl error={touched.portfolioid && errors.portfolioid} sx={{ width: '100%', height: '100%' }}>
                <InputLabel id="portfolio-id-label">Portfolio Selection</InputLabel>
                <Select
                  id="portfolio-id-select"
                  label="Portfolio Selection"
                  labelId="portfolio-id-label"
                  name="portfolioid"
                  onChange={handleSelectChanges}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  value={values.portfolioid}
                  variant="outlined"
                  sx={{ width: '100%', height: '100%', borderRadius: '5px' }}
                  inputProps={{ 'data-testid': 'portfolio-id-select' }}
                >
                  {portfoliosSorted.map(portfolio => (
                    <MenuItem
                      key={portfolio.portfolioid}
                      value={portfolio.portfolioid}
                      data-testid="portfolio-id-option"
                      data-test-option-value={portfolio.portfolioid}
                    >
                      {portfolio.portfolio_title}
                    </MenuItem>
                  ))}
                </Select>
                <Box sx={ERROR_MESSAGE_CONTAINER_STYLES}>
                  {touched.portfolioid && errors.portfolioid && (
                    <FormHelperText id="portfolio-id-helper-text" error={true} data-testid="portfolio-id-error-message">
                      {errors.portfolioid}
                    </FormHelperText>
                  )}
                </Box>
              </FormControl>
            </Box>
            {shouldDisplayClinicTable && (
              <Box sx={{ width: '100%', height: '100%' }}>
                <StaticScrollTable
                  memoizedColumns={columns}
                  memoizedData={values?.portfolioid ? clinicsSortedWithIdAndChecked : []}
                  isLoading={isLoadingFetchPortfolios || isLoadingFetchClinics || isLoading}
                  tableHeight={400}
                  noRecordsOverlay="Select a portfolio to view the linked clinics."
                  dataTestId="edit-scenario-clinic-table"
                  initialSortState={[{ id: 'select', desc: false }]}
                />
              </Box>
            )}
          </Box>
          {renderFormButtons && renderFormButtons(formikProps)}
        </Box>
      )}
    </form>
  )
}

EditScenarioForm.propTypes = {
  ...formikPropTypes, // Include all formikPropTypes
  // override specific formikPropTypes to be more specific
  values: PropTypes.shape({
    clinic_size: PropTypes.arrayOf(PropTypes.number).isRequired,
    portfolioid: PropTypes.string.isRequired,
    scenario_title: PropTypes.string.isRequired,
    therapist_type: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])).isRequired,
    therapy_type: PropTypes.string.isRequired,
    visit_type: PropTypes.string.isRequired,
    years: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  }).isRequired,
  errors: PropTypes.shape({
    // Errors will contain the yup error message
    clinic_size: PropTypes.string,
    portfolioid: PropTypes.string,
    scenario_title: PropTypes.string,
    therapist_type: PropTypes.string,
    therapy_type: PropTypes.string,
    visit_type: PropTypes.string,
    years: PropTypes.string,
  }).isRequired,
  touched: PropTypes.shape({
    clinic_size: PropTypes.bool.isRequired,
    portfolioid: PropTypes.bool.isRequired,
    scenario_title: PropTypes.bool.isRequired,
    therapist_type: PropTypes.bool.isRequired,
    therapy_type: PropTypes.bool.isRequired,
    visit_type: PropTypes.bool.isRequired,
    years: PropTypes.bool.isRequired,
  }).isRequired,
  initialValues: PropTypes.shape({
    clinic_size: PropTypes.arrayOf(PropTypes.number).isRequired,
    portfolioid: PropTypes.string.isRequired,
    scenario_title: PropTypes.string.isRequired,
    therapist_type: PropTypes.arrayOf(PropTypes.string).isRequired,
    therapy_type: PropTypes.string.isRequired,
    visit_type: PropTypes.string.isRequired,
    years: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  }).isRequired,
  initialErrors: PropTypes.shape({
    clinic_size: PropTypes.string,
    portfolioid: PropTypes.string,
    scenario_title: PropTypes.string,
    therapist_type: PropTypes.string,
    therapy_type: PropTypes.string,
    visit_type: PropTypes.string,
    years: PropTypes.string,
  }).isRequired,
  initialTouched: PropTypes.shape({
    clinic_size: PropTypes.bool.isRequired,
    portfolioid: PropTypes.bool.isRequired,
    scenario_title: PropTypes.bool.isRequired,
    therapist_type: PropTypes.bool.isRequired,
    therapy_type: PropTypes.bool.isRequired,
    visit_type: PropTypes.bool.isRequired,
    years: PropTypes.bool.isRequired,
  }).isRequired,
  status: PropTypes.shape({
    shouldReset: PropTypes.bool.isRequired,
  }).isRequired,
  initialStatus: PropTypes.shape({
    shouldReset: PropTypes.bool.isRequired,
  }).isRequired,
  // Other props that are not from formik
  isLoading: PropTypes.bool.isRequired,
  isUnitTest: PropTypes.bool,
  shouldDisplayClinicTable: PropTypes.bool.isRequired,
}

import React from 'react'
import { Card, CardContent, Grid } from '@mui/material'
import 'color-legend-element'
import StateMap from '../stateMap/stateMap.js'

export default function MapCard(props) {
  const data = props.data
  const geoMapStatesData = props.geoMapStatesData

  const domainArray = JSON.parse(props?.metadata?.domain || '[]')
  const sortDomain = domainArray.sort((max, min) => max - min)
  const minMaxLegend = JSON.stringify(sortDomain)
  return (
    <Card sx={{ paddingX: 2, margin: 0 }}>
      <CardContent>
        <Grid
          container
          display="flex"
          direction="column"
          alignItems="center"
          justifyContent="center"
          padding={0}
          maxHeight="600px"
          maxWidth="100%"
        >
          {data ? (
            <StateMap
              data={data}
              geoMapStatesData={geoMapStatesData}
              selectedValueDisplay={props.selectedValueDisplay}
            />
          ) : null}
        </Grid>
        <Grid
          container
          display="flex"
          direction="column"
          alignItems="center"
          justifyContent="center"
          paddingBottom={0}
          maxHeight="600px"
          maxWidth="90%"
        >
          <Grid item lg={4} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
            {props.selectedValueDisplay === 'yoy' ? (
              <color-legend
                range='[
                  "#cfebff",
                  "#b7dbf8",
                  "#a0caf1",
                  "#8bbaeb",
                  "#78a9e4",
                  "#6798dc",
                  "#5887d4",
                  "#4c75cb",
                  "#4463c0",
                  "#3f51b5"]'
                domain={minMaxLegend}
                tickFormat=".0f"
                titleText={'Efficiency Metric: ' + props.selectedNameMetric}
                marginLeft="1"
                marginRight="1"
                scaleType="continuous"
                style={{
                  '--cle-background': '#e5e5e5',
                  '--cle-border-radius': '6px',
                  '--cle-padding': '1rem',
                }}
              ></color-legend>
            ) : (
              <color-legend
                range='[
                  "#cfebff",
                  "#b7dbf8",
                  "#a0caf1",
                  "#8bbaeb",
                  "#78a9e4",
                  "#6798dc",
                  "#5887d4",
                  "#4c75cb",
                  "#4463c0",
                  "#3f51b5"]'
                domain={minMaxLegend}
                tickFormat=".0f"
                titleText={'Efficiency Metric: ' + props.selectedNameMetric}
                marginLeft="1"
                marginRight="1"
                scaleType="continuous"
                style={{
                  '--cle-background': '#e5e5e5',
                  '--cle-border-radius': '6px',
                  '--cle-padding': '1rem',
                }}
              ></color-legend>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

import React from 'react'

/**
 * Custom hook for managing storage values.
 *
 * @param {string} key - The key used to store the value in the storage object.
 * @param {any} defaultValue - The default value to be used if no value is found in the storage object.
 * @param {Storage} storageObject - The storage object to be used for storing and retrieving values.
 * @returns {Object} - An object containing the storage value, a function to set the storage value, and a function to remove the storage value.
 * @throws {Error} - If an invalid storage object is provided.
 */
const useStorage = (key, defaultValue = null, storageObject) => {
  if (!storageObject || typeof storageObject.getItem !== 'function' || typeof storageObject.setItem !== 'function') {
    throw new Error('Invalid storage object provided')
  }
  const [storageValue, setStorageValue] = React.useState(() => {
    try {
      const jsonValue = storageObject.getItem(key)
      if (jsonValue != null) return JSON.parse(jsonValue)
    } catch (error) {
      console.error('Error parsing JSON from storage:', error)
    }
    if (typeof defaultValue === 'function') {
      return defaultValue()
    } else {
      return defaultValue
    }
  })

  React.useEffect(() => {
    try {
      if (storageValue) {
        storageObject.setItem(key, JSON.stringify(storageValue))
      }
    } catch (error) {
      console.error('Error setting item in storage:', error)
    }
  }, [key, storageValue, storageObject])

  const removeStorageValue = React.useCallback(() => {
    try {
      storageObject.removeItem(key)
      setStorageValue(undefined)
    } catch (error) {
      console.error('Error removing item from storage:', error)
    }
  }, [key, storageObject])

  const setSessionStorageItem = React.useCallback(
    value => {
      try {
        storageObject.setItem(key, JSON.stringify(value))
      } catch (error) {
        console.error(error)
      }
    },
    [key, storageObject],
  )

  return { storageValue, setStorageValue, removeStorageValue, setSessionStorageItem }
}

/**
 * Custom hook for managing session storage.
 *
 * @param {string} key - The key for the session storage item.
 * @param {any} defaultValue - The default value for the session storage item.
 * @returns {any} - The value stored in the session storage.
 * @throws {Error} - If the key is not a non-empty string.
 */
export const useSessionStorage = (key, defaultValue) => {
  if (typeof key !== 'string' || key.trim() === '') {
    throw new Error('Key must be a non-empty string')
  }
  return useStorage(key, defaultValue, sessionStorage)
}

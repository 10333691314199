import React from 'react'
import { useToastContext } from '../../../ToastContext/useToastContext'
import { getScenarioParams } from '../../../../services/apis'
import { sessionItems } from '../../../../utils/sessionStoreManage'
import { useSessionStorage } from '../../../../hooks/useSessionStorage/useSessionStorage'

/**
 * Custom hook for fetching scenario parameters.
 *
 * @param {Object} options - The options for the hook.
 * @param {boolean} options.defaultIsLoading - The default value for isLoading state.
 * @returns {Object} - An object containing isLoading, error, scenarioParamsWithMetadata, and fetchScenarioParams.
 * @returns {boolean} object.isLoading - Whether the scenario parameters are loading.
 * @returns {Object} object.error - Any error that occurred while fetching the scenario parameters.
 * @returns {Object} object.scenarioParamsWithMetadata - The selected scenario parameters.
 * @returns {function} object.fetchScenarioParams - A function to fetch scenario parameters.
 */
export const useFetchScenarioParams = ({ defaultIsLoading = false } = { defaultIsLoading: false }) => {
  const { addToast } = useToastContext()
  const { storageValue, setStorageValue, setSessionStorageItem } = useSessionStorage(sessionItems.SCENARIO_PARAMS)

  /**
   * @typedef {Object|null} scenarioParamsWithMetadata
   * @property {Array} scenario_metadata - An array of objects containing metadata for the scenario.
   * @property {string} scenario_metadata[].cached_benchmark_table - The name of the cached benchmark table.
   * @property {number} scenario_metadata[].create_date - The creation date of the scenario.
   * @property {number} scenario_metadata[].created_by_userid - The user ID of the creator of the scenario.
   * @property {boolean} scenario_metadata[].is_temp - Whether the scenario is temporary.
   * @property {?string} scenario_metadata[].scenario_description - The description of the scenario.
   * @property {string} scenario_metadata[].scenario_title - The title of the scenario.
   * @property {string} scenario_metadata[].scenarioid - The ID of the scenario.
   * @property {Array} scenario_parameters - An array of objects containing parameters for the scenario.
   * @property {string} scenario_parameters[].param - The name of the parameter.
   * @property {string} scenario_parameters[].param_type - The type of the parameter. Can be 'list', 'dict', or 'string'.
   * @property {(Array|string|Object)} scenario_parameters[].param_value - The value of the parameter. Can be an array, string, or object depending on the param_type.
   */
  const [scenarioParamsWithMetadata, setScenarioParamsWithMetadata] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(defaultIsLoading)
  const [error, setError] = React.useState(null)
  const [scenarioParamStoredValue, setScenarioParamStoredValue] = React.useState(null)
  const [scenarioParamsValues, setScenarioParamsValues] = React.useState(null)

  /**
   * fetchScenarioParams - Fetches scenario parameters using the provided access token and scenario ID.
   *
   * @param {Object} options - The options for fetching scenario parameters.
   * @param {string} options.accessToken - The access token for authentication.
   * @param {string} options.scenarioId - The ID of the scenario.
   * @param {string} options.errorToastId - The ID of the toast to display if an error occurs.
   * @returns {Promise<void>} - A promise that resolves when the scenario parameters are fetched.
   */
  const fetchScenarioParams = React.useCallback(
    async ({ accessToken, scenarioId, errorToastId }) => {
      try {
        if (!accessToken || !scenarioId || !errorToastId) {
          throw new Error('accessToken, scenarioId and errorToastId are required')
        }
        setIsLoading(true)
        const scenarioParamsWithMetadata = await getScenarioParams(accessToken, {
          scenarioid: scenarioId,
        })

        setScenarioParamsWithMetadata(scenarioParamsWithMetadata.data)
      } catch (error) {
        console.error(error)
        setError(error)
        setScenarioParamsWithMetadata(null)
        addToast({
          toastId: errorToastId,
          variant: 'error',
          msg: `Error loading scenario parameters - ${error?.message || 'unknown error'}`,
        })
      } finally {
        setIsLoading(false)
      }
    },
    [addToast],
  )

  /**
   * Making a copy of the scenario parameters with metadata.
   * @property {Object} scenarioParamsWithMetadata - An object containing metadata and parameters for the scenario.
   */
  React.useEffect(() => {
    if (scenarioParamsWithMetadata) {
      const scenario_data = JSON.parse(JSON.stringify(scenarioParamsWithMetadata?.scenario_metadata[0]))
      const scenario_params = JSON.parse(JSON.stringify(scenarioParamsWithMetadata?.scenario_parameters))
      const scenario_params_with_metadata = {
        scenario_metadata: [
          {
            scenarioid: scenario_data.scenarioid,
            scenario_title: scenario_data.scenario_title,
          },
        ],
        scenario_parameters: scenario_params,
      }
      setScenarioParamsValues(scenario_params_with_metadata)
    } else {
      setScenarioParamsValues(null)
    }
  }, [scenarioParamsWithMetadata])

  const fetchStoredScenarioParams = React.useCallback(
    async ({ scenarioId }) => {
      try {
        const isItAnotherScenario = storageValue?.scenario_metadata[0]?.scenarioid === scenarioId

        if (!storageValue || !isItAnotherScenario) {
          setSessionStorageItem(scenarioParamsValues)
          setStorageValue(scenarioParamsValues)
          setScenarioParamStoredValue(null)
        }
        setScenarioParamStoredValue(storageValue)
      } catch (error) {
        console.error(error)
        setError(error)
        setScenarioParamStoredValue(null)
      }
    },
    [scenarioParamsValues, setSessionStorageItem, setStorageValue, storageValue],
  )

  return {
    isLoading,
    error,
    scenarioParamsWithMetadata,
    fetchScenarioParams,
    scenarioParamStoredValue,
    fetchStoredScenarioParams,
    scenarioParamsValues,
  }
}
